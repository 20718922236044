<template>
    <div id="main">
        <el-main>
            <bread-crumb :type="2" :step="3"></bread-crumb>
            <div class="section">
                <!--<comm-step :type="2" :percentage="75"></comm-step>-->
                <comm-step :active="2" :percentage="50" :type="3"></comm-step>
                <div style="height: 150px"></div>
                <div class="inputBox">
                    <div class="testBox">邀请码：</div>
                    <div
                            style="width: 522px; display: flex; justify-content: space-between"
                    >
                        <el-input
                                maxlength="19"
                                :disabled="!inputFlag"
                                :autofocus="true"
                                ref="input0"
                                @input="changeValue1"
                                @focus="getFocus(0, $event)"
                                v-model="invatation1"
                        >
                        </el-input>
                        <el-input
                                :disabled="!inputFlag"
                                maxlength="4"
                                ref="input1"
                                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                                @input="changeValue"
                                @focus="getFocus(1, $event)"
                                v-model="invatation2"
                        >
                        </el-input>
                        <el-input
                                :disabled="!inputFlag"
                                max="4"
                                ref="input2"
                                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                                @input="changeValue"
                                @focus="getFocus(2, $event)"
                                v-model="invatation3"
                        >
                        </el-input>
                        <el-input
                                :disabled="!inputFlag"
                                max="4"
                                ref="input3"
                                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                                @input="changeValue"
                                @focus="getFocus(3, $event)"
                                v-model="invatation4"
                        >
                        </el-input>
                    </div>
                </div>
                <div style="color: #ee7602;width: 56%;margin: 41px auto 0;font-size: 14px;">
                    注：<strong>如无邀请码，直接进入下一步。</strong>如报名时未填写邀请码，在报名成功后仍可补填。每个邀请码只能与1位用户绑定，且只能使用一次。
                </div>
                <div class="btnBox">
                    <!--<el-button
                            class="cancelCode"
                            @click="cancelCode()"
                            v-if="huixianData.status === 3 && btnFlag === true"
                    >
                        解绑邀请码
                    </el-button>-->
                    <el-button :disabled="isDisable" @click="next()"> 下一步</el-button>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
    import commStep from "c/commStep";
    import breadCrumb from "c/breadcrumb";
    import store from "@/store";
    // import {setInviteCode, cancelInviteCode, signUpInit} from "r/index/signUp";
    import {setInviteCode, cancelInviteCode} from "r/index/signUp";
    import {SignUpInitStatus} from "common/request";

    export default {
        components: {commStep, breadCrumb},
        data() {
            return {
                examuuid: "",
                invatation1: "",
                invatation2: "",
                invatation3: "",
                invatation4: "",
                flag: "", //用来判断是否是返回到本页面
                inputFlag: true,
                huixianData: {},
                btnFlag: false,
                isDisable: false//禁用 false-不禁用，true-禁用
            };
        },
        watch: {
            invatation1: function () {
                this.invatation1 = this.invatation1
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation2: function () {
                this.invatation2 = this.invatation2
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation3: function () {
                this.invatation3 = this.invatation3
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation4: function () {
                this.invatation4 = this.invatation4
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            dialogShowState: function () {
                // console.log(this.dialogShowState,'this.dialogShowState');
                this.dialogShow = this.dialogShowState;
            },
        },
        created() {
            if (!window.localStorage.getItem('exam_uuid') || window.localStorage.getItem('exam_uuid') == '' || window.localStorage.getItem('exam_uuid') == 'null') {
                return this.$router.replace({name: 'examlist'})
            }
            this.examuuid = window.localStorage.getItem("exam_uuid");
            this.hasYQM();
        },
        methods: {
            //第一个输入框
            changeValue1(e) {
                console.log(e, "输入");
                if (e.length == 19 && e.indexOf("-") !== -1) {
                    //如果是复制过来的带'-'邀请码
                    let copyCode = e.split("-");
                    console.log(copyCode, "输入");
                    this.invatation1 = copyCode[0];
                    this.invatation2 = copyCode[1];
                    this.invatation3 = copyCode[2];
                    this.invatation4 = copyCode[3];
                    this.$refs.input3.focus();
                } else if (e.length > 4) {
                    this.changeValue(e.substring(0, 4));
                } else {
                    this.changeValue(e);
                }
            },
            changeValue(e) {
                if (e.length >= 4) {
                    console.log(e, "length");
                    console.log(this.currentIndex, "this.currentIndex");
                    switch (this.currentIndex) {
                        case 0:
                            // console.log(this.text0.length);
                            if (this.invatation1.length >= 4) {
                                this.$refs.input1.focus();
                            }
                            break;
                        case 1:
                            if (this.invatation2.length >= 4) {
                                this.$refs.input2.focus();
                            }
                            // this.$refs.input2.focus();
                            break;
                        case 2:
                            if (this.invatation3.length >= 4) {
                                this.$refs.input3.focus();
                            }
                            // this.$refs.input3.focus();
                            break;
                        case 3:
                            if (this.invatation4.length >= 4) {
                                this.$refs.input3.focus();
                            }
                            // this.$refs.input3.focus();
                            break;
                        default:
                            break;
                    }
                } else if (e.length === 0) {
                    switch (this.currentIndex) {
                        case 0:
                            this.$refs.input0.focus();
                            break;
                        case 1:
                            this.$refs.input0.focus();
                            break;
                        case 2:
                            this.$refs.input1.focus();
                            break;
                        case 3:
                            this.$refs.input2.focus();
                            break;
                        default:
                            break;
                    }
                } else {
                }
            },
            //判断是否已绑定邀请码
            async hasYQM() {
                const data = {
                    exam_uuid: this.examuuid,
                    is_check: 1,
                };
                const {data: res} = await cancelInviteCode(data);
                console.log(res, "判断是否绑定邀请码");
                if (res.code == 200) {
                    this.huixianData = res.data;
                    let status = res.data.status; //status是3的时候才能够解绑
                    let code = res.data.invitation_code; //回显的邀请码
                    if (status === 3 && code != "") {
                        this.btnFlag = true;
                    }
                    if (code) {
                        let copyCode = code.split("-");
                        this.invatation1 = copyCode[0];
                        this.invatation2 = copyCode[1];
                        this.invatation3 = copyCode[2];
                        this.invatation4 = copyCode[3];
                        this.inputFlag = false;
                    }
                }
            },
            //解绑邀请码
            cancelCode() {
                this.$confirm("确定要解绑吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.cancelInvite();
                    })
                    .catch(() => {
                        this.$message.info("您已取消解除绑定");
                    });
            },
            //解绑邀请码执行函数
            async cancelInvite() {
                let code =
                    this.invatation1 +
                    "-" +
                    this.invatation2 +
                    "-" +
                    this.invatation3 +
                    "-" +
                    this.invatation4;
                console.log(code, "返回code");
                let data = {
                    code: code,
                    exam_uuid: this.examuuid,
                };
                const {data: res} = await cancelInviteCode(data);
                if (res.code == 200) {
                    this.invatation1 = "";
                    this.invatation2 = "";
                    this.invatation3 = "";
                    this.invatation4 = "";
                    this.inputFlag = true;
                    this.btnFlag = false;
                    this.$message.success(res.message);
                } else {
                    if (res.code == 4002141) {
                        window.location.reload()
                    } else if (res.code == 4002161) {
                        // await this.getSignUpInit()
                        await SignUpInitStatus(this.examuuid);
                    }
                    this.$message.error(res.message);
                }
            },
            getFocus(index, e) {
                this.currentIndex = index;
            },
            // 下一步
            async next() {
                // let type = await this.getSignUpInit();
                let type = await SignUpInitStatus(this.examuuid);
                if (type) {
                    return;
                }
                let code;
                if (
                    this.invatation1 == "" &&
                    this.invatation2 == "" &&
                    this.invatation3 == "" &&
                    this.invatation4 == ""
                ) {
                    code =
                        this.invatation1 +
                        this.invatation2 +
                        this.invatation3 +
                        this.invatation4;
                } else {
                    code =
                        this.invatation1 +
                        "-" +
                        this.invatation2 +
                        "-" +
                        this.invatation3 +
                        "-" +
                        this.invatation4;
                }
                if (this.btnFlag) {
                    await this.$router.replace({name: "SignUpLists"});
                } else {
                    if (this.isDisable) {
                        return;
                    }
                    this.isDisable = true;
                    const {data: res} = await setInviteCode({
                        exam_uuid: this.examuuid,
                        code: code,
                    });
                    if (res.code !== 200) {
                        this.isDisable = false;
                        return this.$message.error(res.message);
                    }
                    this.isDisable = false;
                    this.$message.success(res.message);
                    await this.$router.replace({name: "SignUpLists"});
                }
            },
            async getSignUpInit() {
                // const {data: res} = await signUpInit({exam_uuid: this.examuuid});
                // console.log(res, "报名初始化完成");
                // if (res.code !== 200) {
                //     if (res.code == 4002139) {
                //         await this.$router.replace({name: 'StepOne', query: {applicationstatus: 0}})
                //         this.$message.info(res.message);
                //         return true;
                //     } else if (res.code == 4002131) {
                //         await this.$router.replace({name: 'OrderList'})
                //         this.$message.info(res.message);
                //         return true;
                //     }
                //     this.$message.error(res.message);
                //     return true;
                // }
                // window.localStorage.setItem("exam_uuid", res.exam_uuid);
                // window.localStorage.setItem("order_no", res.order_no);
                // if (res.status == 2) {
                //     // 跳过邀请码
                //     console.log("报名-选择考点城市");
                //     return false;
                // } else if (res.status == 3) {
                //     // 绑定邀请码
                //     console.log("报名-选择考点城市");
                //     await this.$router.push({name: "SignUpLists"});
                //     return true;
                // } else if (res.status == 4) {
                //     console.log("报名-确认报名信息");
                //     await this.$router.push({name: "ConfirmInfo"});
                //     return true;
                // } else if (res.status == 5) {
                //     console.log("报名-待支付状态");
                //     await this.$router.push({name: "ConfirmInfo"});
                //     return true;
                // } else if (res.status == 7) {
                //     console.log("报名-订单超时");
                //     await this.$router.push({name: "SignUpLists"});
                //     return true;
                // } else if (res.status == 8) {
                //     console.log("报名-取消报名");
                //     return false;
                // } else {
                //     return false;
                // }
            }
        },
    };
</script>

<style scoped lang="scss">
    #main {
        width: 100%;
        overflow: auto;
        min-height: 80%;

        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 0 50px;
        }

        .section {
            margin: auto;
            background: #fff;
            padding: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .inputBox {
                width: 83%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .testBox {
                    width: 64px;
                    height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
            }

            .btnBox {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 100px;
            }
        }
    }

    ::v-deep .el-input__inner {
        width: 120px;
        height: 40px;
        text-align: center;
        flex: 1;
        border-radius: 4px;
        border: 1px solid #e1e3e6;
    }

    ::v-deep .el-button {
        background: #563279;
        color: #fff;
        width: 140px;
        height: 48px;
    }

    ::v-deep .btnBox .cancelCode.el-button {
        background: #fff;
        color: #563279;
        border: 1px solid #563279;
        margin-right: 20px;
    }
</style>
